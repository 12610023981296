<template>
  <div class="member">
    <div class="title">
      <div class="txt">俱乐部动态</div>
      <div class="more" @click="goMore">查看更多 》</div>
    </div>
    <div class="Infobox" v-loading="loading">
      <div v-if="total>0">
        <div class="top" @click="$router.push(`/detail?id=${one[0].id}`)">
          <img :src="one[0].img_url || imgUrl" alt="" />
          <div>
            <div class="tit">
              {{ one[0].title }}
            </div>
            <div class="time">{{ one[0].created_time | secondFormat("L") }}</div>
          </div>
        </div>
        <div class="bottom">
          <div
            class="item"
            v-for="(item, index) in data"
            :key="index"
            @click="goDetail(item.id)"
          >
            <div class="icon"></div>
            <div class="txt">{{ item.title }}</div>
            <div class="time">{{ item.created_time | secondFormat("L") }}</div>
          </div>
        </div>
      </div>
      <noDataImg v-else></noDataImg>
    </div>
  </div>
</template>

<script>
export default {
  name: "culbDynamic",
  components: {},
  data() {
    return {
      loading: false,
      type: 68,
      one: [],
      data: [],
      total: 0,
      imgUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hph/img_culbDynamic.png'
    };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
    this.getStaticContent();
    
  },
  methods: {
    goMore() {
      this.$router.push("/activitys?content_type=68");
    },
    goDetail(id) {
      this.$router.push(`/detail?id=${id}`);
    },
    async getStaticContent() {
      this.loading = true
      let params = {
        content_type: this.type,
        source: this.PJSource,
        nopage: 0,
        limit: 5,
        start: 0,
        is_show: 1,
        language: this.LOCALE == "en" ? 1 : 2,
      };
      let res = await this.$store.dispatch(
        "API_index/getStaticContent",
        params
      );
      if (res.success) {
        this.one = res.data.splice(0, 1);
        this.data = res.data;
        this.total = res.total
        this.loading = false
      }
    },
  },
};
</script> 

<style scoped lang="less">
.member {
  width: 588px;
  margin-bottom: 67px;
}
.Infobox {
  position: relative;
  height: 264px;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  .txt {
    line-height: 18px;
    font-size: 18px;
    color: #333;
    font-weight: 800;
  }
  .more {
    font-size: 14px;
    color: #0d6bb9;
    cursor: pointer;
  }
}

.top {
  display: flex;
  cursor: pointer;
  img {
    width: 179px;
    height: 112px;
    margin-right: 17px;
  }
  .tit {
    font-size: 16px;
    line-height: 18px;
    color: #333333;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .time {
    font-size: 12px;
    color: #999999;
    margin-top: 15px;
  }
}

.bottom {
  .item {
    display: flex;
    align-items: center;
    margin-top: 16px;
    cursor: pointer;
    .icon {
      width: 22px;
      height: 22px;
      background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hph/icon/icon-horn.png");
    }
    .txt {
      font-size: 14px;
      line-height: 14PX;
      color: #535353;
      width: 500PX;
      margin: 0 20px 0 11px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .time {
      font-size: 12px;
      color: #999999;
    }
    &:hover {
      .icon {
        background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hph/icon/icon_horn_art.png");
      }
      .txt {
        color: #0d6bb9;
      }
    }
  }
}
</style>