<template>
  <div class="box" >
    <table></table>
    <div class="title">
      <img class="icon_img" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hph/icon/icon_education.png" alt="">
      <div class="txt" @click="handleMore">教育培训</div>
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in dataList.slice(0,3)" :key="index">
        <div class="img pointer img-wrap">
          <al-image
            :src="item.cover_url"
            fit="scale-down"
            class="item-img"
          ></al-image>
          <div class="mark">
            <i
              class="el-icon-video-play cursor"
              @click="handleContent(item)"
            ></i>
          </div>
        </div>
        <div class="item-txt">
          {{item.name}}
        </div>
      </div>
      <i></i>
      <i></i>
      <i></i>
    </div>
    <videoPlay ref="vidoePlay"></videoPlay>
  </div>
</template>

<script>
import videoPlay from "@/baseComponents/videoPlay";
export default {
  name: "education",
  components: {
    videoPlay,
  },
  data() {
    return {
      dataList: [],
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      this.loading = true;
      let params = {
        source: this.PJSource,
        start: 0,
        limit: 3,
        home_flag: 1,
      };
      let res = await this.$store.dispatch(
        "API_company/association_video_List",
        params
      );
      this.loading = false;
      if (res.success) {
        this.dataList = res.data;
      }
    },
    handleMore() {
      this.$router.push('/education_index')
    },
    handleContent(item) {
      if (this.IS_LOGIN) {
        this.$refs.vidoePlay.startPlay(item.aliyun_id);
      } else {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            this.$refs.vidoePlay.startPlay(item.aliyun_id);
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.box {
  margin-top: 35px;
  .title {
    display: flex;
    justify-content:center;
    margin-top: 70px;
    .icon_img {
      width: 42px;
      height: 32px;
      color: #fff;
      margin:2px 21px 0 0;
    }
    .txt {
      font-size: 28px;
      font-weight: bold;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
  .list {
    height: 265px;
    margin-top: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .item {
      width: 384px;
      height: 216px;
      .img {
        width: 100%;
        margin-bottom: 13px;
        height: 100%;
        overflow: hidden;
        .img-img {
          width: 100%;
          height: 100%;
        }
      }
      .item-txt {
        line-height: 17px;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
    & > i {
      width: 384px;
    }
    .item:nth-child(2) {
      width: 400px;
      height: 232px;
      .img {
        margin-bottom: 16px;
      }
    }
  }
}
.pointer {
  cursor: pointer;
}
.img-wrap {
  position: relative;
  .mark {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    line-height: 170px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-icon-video-play {
      font-size: 30px;
      color: #aaa;
    }
  }
  .mark:hover {
    background: rgba(0, 0, 0, 0.6);
  }
  .mark:hover .el-icon-video-play {
    color: #fff;
  }
}
</style>