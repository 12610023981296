<template>
  <div class="imgItemBox">
    <div class="imgItem" v-for="(item, i) in imgUrl" :key="i">
      <img :src="item.url" alt="" @click="goPage(item)" />
      
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible_company" width="300px">
      <div>
        <div class="openTitle">
          暂无企业信息, 请到控制台-企业设置 完善您的公司信息
        </div>
        <div class="tc open">
          完善企业信息<i class="el-icon-right cursor" @click="toConsole"></i>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="提示"
      :visible.sync="isMumber"
      width="300px"
      :close-on-click-modal="true"
    >
      <div class="tc openDialogs">
        <span class="blue" @click="goToMember">成为会员</span>,解锁专属权益
      </div>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="dialogVisible_admin" width="350px">
      <div>
        <div class="openTitle">您不是企业管理员，请联系企业管理员操作入会</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "imgItem",

  data() {
    return {
      dialogVisible_company: false,
      dialogVisible_admin: false,
      isMumber: false,
      imgUrl: [
        {
          id: 1,
          url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hph/joinMember.png",
        },
        // {
        //   id: 2,
        //   url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/putOnRecords.png",
        // },
        // {
        //   id: 3,
        //   url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/interflow.png",
        // },
      ],
    };
  },

  mounted() {},

  methods: {
    goPage(item) {
      if (item.id == 1) {
        if (!this.IS_LOGIN) {
          return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
        } else if (!this.estimateUserInfo(true, !this.USER_INFO.is_admin)) {
          return;
        } else {
          this.$router.push("/memberShip");
        }
      }

      if (item.id == 2) {
        this.$router.push("/putOnRecords?content_type=226");
      }

      // if (item.id == 3) {
      //   // 企业交流平台
      // }
    },
    estimateUserInfo(company = true, is_admin = false) {
      if (!this.USER_INFO.company_id && company) {
        this.dialogVisible_company = true;
        return false;
      } else if (is_admin) {
        this.dialogVisible_admin = true;
        return false;
      } else {
        return true;
      }
    },
    toConsole() {
      this.$router.push({ path: "/console/company/basic" });
    },
    goToMember() {
      this.isMumber = false;
      this.$router.push("/memberShip");
    },
  },
};
</script>

<style lang="less" scoped>
.imgItemBox {
  width: 373px;
  height: 287px;  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  img {
    width: 100%;
    height: 88px;
    margin-bottom: 20px;
  }
}
</style>