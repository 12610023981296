<template>
    <div class="talent">
        <div class="title">
            <img class="icon_img" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hph/icon/icon_%20talent.png" alt="">
            <div class="txt">人才专区</div>
        </div>
        <div class="listBox" v-loading="loading">
            <div class="list" v-for="(item, index) in talentList.slice(0,6)" :key="index">
                <div class="attache" @click="goToPositionDetail(item.id)">{{item.name}}<span> — </span>{{item.city_name_zh}}</div>
                <div class="company">{{item.name_zh}}</div>
            </div>
            <noDataImg class="noDataImg" v-if="!loading && talentList.length === 0"></noDataImg> 
        </div>   
    </div>
</template>

<script>
export default {
    name: 'talentZone',

    data() {
        return {
            loading: false,
            talentList: [],
        };
    },

    mounted() {
        this.getList();
    },

    methods: {
        async getList() {
            // 获取职位推荐
            this.loading = true;
            let parmas = {
                start: 0,
                limit: 6,
                is_hot: 1,
                source: this.PJSource,
            };
            let data = await this.$store.dispatch(
                "API_position/getJobPositionList",
                parmas
            );
            this.loading = false;
            if (data.success) {
                this.talentList = data.data;
            }
        },
        goToPositionDetail(id) {
            let res = this.$router.resolve({
                path: "/positionDetail",
                query: {
                parameter: this._encode({
                    id: id,
                }),
                },
            });
            window.open(res.href, "_blank");
        },
    },
};
</script>

<style lang="less" scoped>
.talent{
    width: 385px;
    .title {
        margin-bottom: 25px;
        display: flex;
        .icon_img {
            width: 34px;
            height: 26px;
            margin-right: 16px;
        }
        .txt {
            font-size: 28px;
            line-height: 28px;
            font-weight: bold;
            color: #333333;
        }
    }
    .listBox {
        position: relative;
        .noDataImg {
            top: 100px;
        }
        .list {
            display: flex;
            justify-content: space-between;
            .attache {
                margin-bottom: 30px;
                line-height: 14px;
            }
            .attache:hover {
                color: #125DB2;
                cursor: pointer;
            }
            .company {
                color: #125DB2;
                font-size: 14px;
                width: 210px;
                white-space:nowrap;
                text-overflow:ellipsis;
                overflow: hidden;
                line-height: 14px;
            }
        }
    }
}
</style>