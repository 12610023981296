<template>
    <div class="container">
        <carouselBanner paddingTop="24%" :bannerType="1"></carouselBanner>
        <div class="title content_area">
            <div class="line"></div>
            <img class="icon_img" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hph/icon/icon_message.png" alt="">
            <div class="txt">资讯中心</div>
            <div class="line"></div>
        </div>
        <div class="content_area">
            <!-- 行业信息 -->
            <IndustryMessage></IndustryMessage>
            <!-- 俱乐部、会员动态 -->
            <div class="flexBox">
                <CulbDynamic></CulbDynamic>
                <MemberDynamic></MemberDynamic>
            </div>
            <!-- 广告位 -->
            <dvertising1></dvertising1>
            <!-- 人才、下载专区 -->
            <div class="flexBox">
                <TalentZone></TalentZone>
                <DownloadZone></DownloadZone>
                <ImgItem></ImgItem>
            </div>
            
        </div>
        <!-- 教育培训 -->
        <div class="cooperation">
            <div class="content_area">
            <Education></Education>
            </div>
        </div>
        <div class="content_area">
            <Cooperation></Cooperation>
        </div>
    </div>
</template>

<script>
import carouselBanner from "@/baseComponents/carouselBanner";
import IndustryMessage from "~hph/components/index/home/industryMessage"
import CulbDynamic from "~hph/components/index/home/culbDynamic"
import MemberDynamic from "~hph/components/index/home/memberDynamic"
import dvertising1 from "~hph/components/index/home/advertising1";
import TalentZone from "~hph/components/index/home/talentZone"
import DownloadZone from "~hph/components/index/home/downloadZone"
import ImgItem from "~hph/components/index/home/imgItem"
import Education from "~hph/components/index/home/education";
import Cooperation from "~hph/components/index/home/cooperation";
export default {
    metaInfo() {
    return {
        title: '海派汇',
        };
    },
    props: {},
    components: {
        carouselBanner,
        IndustryMessage,
        CulbDynamic,
        MemberDynamic,
        TalentZone,
        DownloadZone,
        dvertising1,
        ImgItem,
        Education,
        Cooperation
    },
  data () {
   return {}
  },
  computed: {},
  watch: {},
  methods: {},

  created () {
  },
  mounted () {
  },
}
</script> 

<style scoped lang="less">
    .flexBox {
        display: flex;
        justify-content: space-between;
    }
    .container {
        background-color: #fff;

        .title {
            display: flex;
            align-items: center;
            // margin: 55px 0 32px 0;
            margin-top: 55px;
            margin-bottom: 32px;
            .line {
                width: 517px;
                height: 1px;
                background: #DFDFDF;
            }
            .icon_img {
                width: 24px;
                height: 27px;
                margin: 0 10px;
            }
            .txt {
                font-size: 28px;
                color: #333;
                font-weight: bold;
                margin-right: 10px;
            }
        }
        .cooperation {
            width: 100%;
            height: 549px;
            background: url(https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hph/img_bg.png);
        }
    }

</style>