<template>
  <div class="industry">
    <div class="title">
      <div class="txt">行业信息</div>
      <div class="more" @click="goViewMore">查看更多 》</div>
    </div>
    <div class="dataInfo">
      <div class="itemBox" v-if="data.length">
        <div class="item" v-for="(item,index) in data" :key="index" @click="goDetail(item.id)">
          <img :src="item.img_url || imgUrl" alt="" />
          <div class="item-tit">
            {{item.title}}
          </div>
          <div class="time">{{item.created_time|secondFormat('LL')}}</div>
        </div>
      </div>
      <noDataImg class="noDataImg" v-else></noDataImg> 
    </div>
  </div>
</template>

<script>
export default {
  name: "industryMessage",
  components: {},
  data() {
    return {
      type: 3,
      data: [],
      imgUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hph/img_industryMessage.png'
    };
  },
  created() {
    this.getStaticContent();
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    async getStaticContent() {
      let params = {
        content_type: this.type,
        source: this.PJSource,
        nopage: 0,
        limit: 4,
        start: 0,
        is_show: 1,
        language: this.LOCALE == "en" ? 1 : 2,
      };
      let res = await this.$store.dispatch(
        "API_index/getStaticContent",
        params
      );
      if (res.success) {
        this.data = res.data;
      }
    },
    goViewMore() {
      this.$router.push('/activitys?content_type=3')
    },
    goDetail(id) {
      this.$router.push(`/detail?id=${id}`)
    }
  },
};
</script> 

<style scoped lang="less">
.industry {
    margin-bottom: 42px;
}
.dataInfo {
  height: 248px;
  position: relative;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19px;
  .txt {
    font-size: 18px;
    color: #333;
    font-weight: 800;
  }
  .more {
    font-size: 14px;
    font-weight: 400;
    color: #0d6bb9;
    cursor: pointer;
  }
}
.itemBox {
  display: flex;
}
.item {
  width: 282px;
  margin-right: 24px;
  cursor: pointer;
  &:nth-child(4) {
    margin-right: 0;
  }
  img {
    width: 282px;
    height: 160px;
  }
  .item-tit {
    font-size: 16px;
    color: #000000;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 15px 0 12px 0;
  }
  .time {
    font-size: 14px;
    color: #999999;
  }
}
</style>