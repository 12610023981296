<template>
  <div class="download">
    <div class="title">
      <img
        class="icon_img"
        src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hph/icon/icon_download.png"
        alt=""
      />
      <div class="txt" @click="goToDownloadSection">下载专区</div>
    </div>
    <div class="list" v-loading="loading">
      <div 
        class="txt" 
        v-for="(it, index) in downloadList.slice(0,6)"
        :key="index"
        @click="downloadChange(it)"
      >
        {{ it.name }}
      </div>
      <noDataImg class="noDataImg" v-if="!loading && downloadList.length == 0"></noDataImg>
    </div>
  </div>
</template>

<script>
export default {
  name: "downloadZone",

  data() {
    return {
      loading: false,
      downloadList: [],
      currentPage: 0,
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    async getList() {
      this.loading = true;
      try {
        let result = await this.$store.dispatch("API_index/downloadList", {
          source: this.PJSource,
          start: this.currentPage,
          nopage: 1,
        });
        if (result.success) {
          this.currentPage++;
          this.loading = false;
          this.downloadList = result.data;
          this.total = result.total;
        } else {
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    downloadChange(item) {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
        return;
      } else {
        window.open(item.url);
      }
    },
    goToDownloadSection() {
      this.$router.push('/downloadSection')
    }
  },
};
</script>

<style lang="less" scoped>
.download {
  width: 350px;
  .title {
    margin-bottom: 25px;
    display: flex;
    .icon_img {
      width: 26px;
      height: 26px;
      margin-right: 16px;
    }
    .txt {
      font-size: 28px;
      line-height: 28px;
      font-weight: bold;
      color: #333333;
      cursor: pointer;

    }
  }
  .list {
    width: 350px;
    position: relative;
    .noDataImg {
      top: 100px;
    }
    .txt {
      width: 262px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 30px;
      color: #333;
      font-size: 14px;
      line-height: 14px;
    }
    .txt:hover {
      color: #2167b7;
      cursor: pointer;
    }
    .txt:nth-child(1) {
      width: 350px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>