<template>
  <div class="cooperationBox">
    <div class="title">
      <img class="icon_img" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hph/icon/icon_message.png" alt="">
      <div class="txt" @click="goToCompanyCatalog">会员单位</div>
    </div>
    <el-carousel height="370px" :autoplay="false" trigger="click" arrow="never" :indicator-position="indicatorShow">
      <el-carousel-item v-for="(itemList, i) in companyList" :key="i">
        <div class="list">
          <div v-for="(item, index) in itemList" :key="index" class="item">
            <el-image
              :src="item.logo || defaultLogo"
              fit="scale-down"
              class="item-img"
              @click="goToDetail(item.id)"
            >
            </el-image>
            
          </div>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div>
      </el-carousel-item>
    </el-carousel>
    
  </div>
</template>

<script>
export default {
  name: 'cooperation',

  data() {
    return {
      indicatorShow:'none',
      total: 0,
      companyList: [],
      defaultLogo:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/company/companyLogo.png",
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    async getList() {
      this.loading = true;
      let params = {
        source: this.PJSource,
        // source: 7,
        start: 0,
        nopage: 0,
        is_vip: 1,
        website: this.PJSource,
        usage: 1,
      };
      let res = await this.$store.dispatch("API_index/getCompanyList", params);
      if (res.success) {
        this.companyList = res.data;
        this.total = res.total;
        if (res.total && res.total>12) {
          this.indicatorShow = ''
        }
        let newCompanyList = []
        let current = 0
        if(this.companyList && this.companyList.length>0){
          for(let i=0;i<=this.companyList.length-1;i++){
            if(i%12 !== 0 || i === 0 ){
              if(!newCompanyList[current]){
                newCompanyList.push([this.companyList[i]])
              }else{
                newCompanyList[current].push(this.companyList[i])
              }
            }else{
              current++
              newCompanyList.push([this.companyList[i]])
            }
          }
        }
        this.companyList = [...newCompanyList]
      }
    },
    goToDetail(id) {
      let res = this.$router.resolve({
        path: "/companyDetail",
        query: {
          parameter: this._encode({
            company_id: id,
          }),
        },
      });
      window.open(res.href, "_blank");
    },
    goToCompanyCatalog() {
      this.$router.push('/companyCatalog')
    }
  },
};
</script>

<style lang="less" scoped>
.cooperationBox {
  height: 534px;
  margin-top: 70px;
  .title {
    display: flex;
    justify-content:center;
    margin-bottom: 56px;
    .icon_img {
      margin-top: 1px;
      width: 26px;
      height: 26px;
      margin-right: 12px;
    }
    .txt {
      font-size: 28px;
      line-height: 28px;
      font-weight: bold;
      color: #333333;
      cursor: pointer;
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 282px;
      height: 88px;
      background: #FFFFFF;
      border: 1px solid #DDDDDD;
      box-shadow: 0px 1px 5px 0px rgba(171, 171, 171, 0.5);
      border-radius: 4px;
      margin-bottom: 23px;
    }
    & > i {
      width: 282px;
    }
  
    .item-img{
      width: 100%;
      height: 100%;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
/deep/.el-carousel__indicator--horizontal {
  padding: 0 8px;
}
/deep/.el-carousel__indicator--horizontal .el-carousel__button {
  width: 14px;
  height: 14px;
  background: #E5E5E5;
  border: 1px solid #E5E5E5;
  border-radius: 50%;
  opacity: 0.5;
}  
/deep/.el-carousel__indicator--horizontal.is-active .el-carousel__button{
    width: 14px;
    height: 14px;
    background: #125DB2;
    border-radius: 50%;
    opacity: 1;
  }
</style>